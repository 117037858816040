<template>
  <v-container fluid>
    <v-col>
      <div class="gift-card-page">
        <router-view />
      </div>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: 'GiftCardPage',
}
</script>

<style lang="scss">

</style>