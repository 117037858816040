import feathersClient, { makeServicePlugin, BaseModel } from '@/plugins/feathers-client'

class GiftCardInstance extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'GiftCardInstance'
  // Define default properties here
  static instanceDefaults() {
    return {
    }
  }
}
const servicePath = 'gift-card/instance'
const servicePlugin = makeServicePlugin({
  Model: GiftCardInstance,
  service: feathersClient.service(servicePath),
  namespace: 'gift-card-instance',
  servicePath
})

feathersClient.service(servicePath).timeout = 30000

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin