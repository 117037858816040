<template>
  <div class="gift-card-instance-list">
    <v-row justify="end">
      <v-col cols="3" class="mb-4">
        <v-btn @click="openCreateDialog" block depressed color="primary">Create Gift Card</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      v-if="instances"
      :options="{ itemsPerPage: -1 }"
      sort-by="purchaseDate"
      :headers="headers"
      :items="instances">
      <template v-slot:item.giftee.first_name="{ item }">
        <a v-if="item.giftee" :href="`https://rayoga.marianatek.com/admin/user/profile/${item.gifteeId}/payment-methods`" target="_BLANK">{{ item.giftee.first_name }} {{ item.giftee.last_name }}</a>
        <span v-if="!item.giftee">N/A</span>
      </template>
      <template v-slot:item.giftee_email="{ item }">
        <span v-if="item.giftee">{{ item.giftee.email }}</span>
        <span v-if="!item.giftee">N/A</span>
      </template>
      <template v-slot:item.code="{ item }">
        {{ item.code }}
      </template>
      <template v-slot:item.isRedeemed="{ item }">
        {{ item.isRedeemed }}
      </template>
      <template v-slot:item.expirationMonths="{ item }">
        {{ item.expiresAt }}
      </template>
      <template v-slot:item.id="{ item }">
        <v-btn v-if="item" :disabled="!item.giftee" x-small color="success" depressed @click="editInstance($event, item)">
          View
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog
      transition="dialog-bottom-transition"
      scrollable
      fullscreen
      dismissable
      v-if="editDialog"
      :value="editDialog"
    >
      <v-card tile>
        <v-container>
          <v-card-title>
            <h2>{{ editItem.code }}</h2>
            <v-spacer /> 
            <v-btn
                icon
                dark
                @click="editDialog = false"
              ><v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider class="mb-4" />
          <v-card-text>
            <v-row>
              <v-col>
                <v-sheet elevation="4" class="pa-4">
                  <h2 class="text-center pb-3">Gifter</h2>
                  <v-divider />
                  <v-simple-table v-if="editItem.gifterId !== 0">
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>{{ editItem.gifter.first_name }} {{ editItem.gifter.last_name }}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{{ editItem.gifter.email }}</td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td>{{ editItem.gifter.phone_number }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <v-btn
                    v-if="editItem.gifterId !== 0"
                    :href="`https://rayoga.marianatek.com/admin/user/profile/${editItem.gifterId}/payment-methods`"
                    class="mt-4"
                    small
                    depressed
                    color="primary"
                    block
                  >
                      View Mariana Profile
                  </v-btn>
                  <v-btn
                    v-if="editItem.gifterId === 0"
                    class="mt-4"
                    small
                    depressed
                    color="primary"
                    block
                    disabled
                  >
                      Gift Card generated by employee
                  </v-btn>
                </v-sheet>
              </v-col>

              <v-col>
                <v-sheet elevation="4" class="pa-4">
                  <h2 class="text-center pb-3">Giftee</h2>
                  <v-divider />
                  <v-simple-table>
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>{{ editItem.giftee.first_name }} {{ editItem.giftee.last_name }}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{{ editItem.giftee.email }}</td>
                      </tr>
                      <tr>
                        <td>Phone</td>
                        <td>{{ editItem.giftee.phone_number }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <v-btn
                      v-if="editItem.gifteeId"
                    :href="`https://rayoga.marianatek.com/admin/user/profile/${editItem.gifteeId}/payment-methods`"
                    class="mt-4"
                    small
                    depressed
                    color="primary"
                    block
                  >
                      View Mariana Profile
                  </v-btn>
                  <v-btn
                      v-if="!editItem.gifteeId"
                    class="mt-4"
                    small
                    depressed
                    color="primary"
                    block
                    disabled
                  >
                      Gift Card not assigned to a customer
                  </v-btn>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Expires: {{ (editItem.expiresAt) ? editItem.expiresAt : 'Never' }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      dismissable
      width="500"
      v-if="createDialog"
      :value="createDialog"
    >
      <v-card tile>
        <v-card-title>
          <h2>Create Gift Card</h2>
        </v-card-title>
        <v-divider class="mb-4" />
        <v-card-text>
          <v-form>
            <v-autocomplete
              :loading="usersLoading"
              outlined
              label="Giftee"
              v-model="giftee"
              :items="users"
              item-text="attributes.email"
              return-object
              :search-input.sync="gifteeSearch"
            />

            <v-btn
              depressed
              color="primary"
              block
              @click="createInstance"
              :loading="instanceCreateLoading"
              :disabled="instanceCreateLoading">
              Create
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GiftCardInstanceList',

  props: [
    'configId',
  ],

  data() {
    return {
      editDialog: false,
      editItem: null,
      createDialog: false,
      createItem: {},
      gifteeSearch: '',
      giftee: null,
      headers: [
        {
          text: 'Giftee',
          value: 'giftee.first_name',
        },
        {
          text: 'Email',
          value: 'giftee.email',
        },
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'Redeemed',
          value: 'isRedeemed',
        },
        {
          text: 'Expires At',
          value: 'expiresAt'
        },
        {
          text: '',
          value: 'id'
        }
      ],
    }
  },

  computed: {
    ...mapGetters({
      instances: 'gift-card-instance/list',
      users: 'mariana-user/list'
    }),

    usersLoading() {
      return this.$store.state['mariana-user'].isFindPending
    },

    instanceCreateLoading() {
      return this.$store.state['gift-card-instance'].isCreatePending
    },
  },

  watch: {
    async gifteeSearch(val) {
      if (this.usersLoading) return
      await this.$store.dispatch('mariana-user/find', {
        query: {
          email_query: val,
        }
      })
    }
  },

  methods: {
    async getInstances() {
      this.$store.dispatch('gift-card-instance/find', {
        query: {
          giftCardConfigId: this.configId,
        }
      })
    },

    async createInstance() {
      if (this.giftee) {
        await this.$store.dispatch('gift-card-instance/create', {
          gifteeId: this.giftee.id,
          gifterId: 0,
          giftCardConfigId: this.$route.params.configId,
        }).then(res => {
          if (res) {
            this.closeCreateDialog()
          }
        })
      } else {
        await this.$store.dispatch('gift-card-instance/create', {
          gifteeId: 0,
          gifterId: 0,
          giftCardConfigId: this.$route.params.configId,
        }).then(res => {
          if (res) {
            this.closeCreateDialog()
          }
        })
      }
    },

    openCreateDialog() {
      this.giftee = null
      this.gifteeSearch = null
      this.createDialog = true;
    },

    closeCreateDialog() {
      this.giftee = null
      this.gifteeSearch = null
      this.createDialog = false
    },

    editInstance(e, item) {
      this.editDialog = true
      this.editItem = item
    }
  },

  async beforeMount() {
    await this.getInstances()
  },

  beforeDestroy() {
    this.$store.commit('gift-card-instance/clearAll')
  },
}
</script>

<style lang="scss">

</style>