<template>
  <div class="gift-card-create-config">
    <v-row>
      <v-col>
        <h1>Create Gift Card Configuration</h1>
        <v-divider class="pb-4" />
      </v-col>
    </v-row>
    <v-sheet class="pa-8">
      <v-form>
        <v-row>
          <v-col>
            <v-text-field outlined label="Name" v-model="config.name" />
          </v-col>
          <v-col>
            <v-text-field
              outlined
              label="Gift Card Variant ID"
              hint="The ID of the MarianaTek product variant for this gift card. When the variant is purchased in MarianaTek this ECCO gift card will be supplied to the customer."
              v-model="config.giftCardVariantId"
              />
          </v-col>
        </v-row>
        
        
        <v-row>
          <v-col>
            <v-select
              outlined
              @change="getProducts()"
              label="Product Type"
              v-model="selectedProductType"
              :items="productTypes"
              item-text="name"
              item-value="value"
            />
          </v-col>
          <v-col v-if="selectedProductType !== 'accountbalance'">
            <v-autocomplete v-if="selectedProductType === null" outlined disabled />
            <v-autocomplete v-if="selectedProductType === 'contracts'" :loading="contractsLoading" outlined label="Contracts" v-model="selectedProduct" :items="contracts" item-text="attributes.name" return-object />
            <v-autocomplete v-if="selectedProductType === 'products'" :loading="productsLoading" outlined label="Products" v-model="selectedProduct" :items="products" item-text="attributes.title" return-object />
            <v-autocomplete v-if="selectedProductType === 'variants'" :loading="variantsLoading" outlined label="Products Variants" v-model="selectedProduct" :items="variants" item-text="attributes.title" return-object />
            <v-autocomplete v-if="selectedProductType === 'credits'" :loading="creditsLoading" outlined label="Credits" v-model="selectedProduct" :items="credits" item-text="attributes.name" return-object />
            <v-autocomplete v-if="selectedProductType === 'packages'" :loading="packagesLoading" outlined label="Packages" v-model="selectedProduct" :items="packages" item-text="attributes.title" return-object />
          </v-col>

          <v-col v-if="selectedProductType === 'accountbalance'">
            <v-text-field
              label="Amount"
              outlined type="number"
              v-model="accountBalanceAmount"
              prefix="$"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              min="-1"
              outlined
              type="number"
              v-model="config.redeemLimit"
              label="Redeem Limit"
              hint="For a gift card with no limit set this to -1"
            />
          </v-col>
          <v-col>
            <v-checkbox
              v-model="config.canBeRedeemedByPurchaser"
              label="Allow purchaser to redeem gift card"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              type="number"
              min="1"
              outlined
              clearable
              v-model="config.expirationMonths"
              label="Months before expiration"
              hint="Once a gift card is purchased it will be valid for this many months before expiring."
              suffix="months"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="config.public"
              label="Public"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="config.requireSavedCard"
              label="Require credit card on file"
            />
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="2">
            <v-btn block color="primary" x-large depressed @click="createConfig">Create</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'GiftCardCreateConfig',

  data() {
    return {
      config: {},
      accountBalanceAmount: null,
      selectedProductType: null,
      selectedProduct: null,
      productTypes: [
        { name: 'Account Balance', value: 'accountbalance' },
        { name: 'Product', value: 'products' },
        { name: 'Contract', value: 'contracts' },
        { name: 'Credit', value: 'credits' },
        { name: 'Product Variant', value: 'variants' },
        { name: 'Package', value: 'packages' },
      ]
    }
  },

  watch: {
    selectedProductType(val) {
      if (val !== 'accountbalance') {
        this.accountBalanceAmount = null
        this.config.isAccountBalance = false
      } else {
        this.config.isAccountBalance = true
      }
    }
  },

  computed: {
    ...mapGetters({
      configs: 'gift-card-config/list',
      contracts: 'contract/list',
      products: 'product/list',
      variants: 'product-variant/list',
      credits: 'credit/list',
      packages: 'package/list',
    }),
    contractsLoading() {
      return this.$store.state['contract'].isFindPending
    },
    productsLoading() {
      return this.$store.state['product'].isFindPending
    },
    variantsLoading() {
      return this.$store.state['product-variant'].isFindPending
    },
    creditsLoading() {
      return this.$store.state['credit'].isFindPending
    },
    packagesLoading() {
      return this.$store.state['package'].isFindPending
    },
  },

  methods: {
    async createConfig() {
      let productName = null

      if (this.selectedProductType === 'contracts' || this.selectedProductType === 'credits') {
        productName = this.selectedProduct.attributes.name
      } else if (this.selectedProductType === 'products' || this.selectedProductType === 'variants' || this.selectedProductType === 'packages') {
        productName = this.selectedProduct.attributes.title
      } else {
        productName = 'Account Balance'
      }

      await this.$store.dispatch('gift-card-config/create', {
        ...this.config,
        amount: (this.config.isAccountBalance && this.accountBalanceAmount) ? (this.accountBalanceAmount * 100) : null,
        productType: this.selectedProductType,
        productId: (this.selectedProduct) ? this.selectedProduct.id : null,
        productName,
      }).then(res => {
        if (res) {
          this.$router.push({ name: 'GiftCardEditConfigRoute', params: { configId: res.id } });
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getProducts() {
      if (this.selectedProductType === 'contracts') {
        this.$store.dispatch('contract/find', {
          query: {
            limit: 200,
          }
        })
      } else if (this.selectedProductType === 'products') {
        this.$store.dispatch('product/find', {
          query: {
            limit: 200,
          }
        })
      } else if (this.selectedProductType === 'variants') {
        this.$store.dispatch('product-variant/find', {
          query: {
            limit: 200,
            is_public: true,
            is_inventoriable: false,
          }
        })
      } else if (this.selectedProductType === 'credits') {
        this.$store.dispatch('credit/find', {
          query: {
            limit: 200,
          }
        })
      } else if (this.selectedProductType === 'packages') {
        this.$store.dispatch('package/find', {
          query: {
            limit: 200,
          }
        })
      }
    },
  },

  async beforeMount() {
  }
}
</script>

<style lang="scss">

</style>