<template>
  <div class="gift-card-config-list">
    <v-row justify="end">
      <v-col cols="3" class="mb-4">
        <v-btn @click="createConfig" block depressed color="primary">Add Config</v-btn>
      </v-col>
    </v-row>
    <v-data-table
      v-if="configs"
      :options="{ itemsPerPage: -1 }"
      sort-by="purchaseDate"
      :headers="headers"
      :items="configs">
      <template v-slot:item.name="{ item }">
        {{ item.name }}
      </template>
      <template v-slot:item.productName="{ item }">
        {{ item.productName }}
      </template>
      <template v-slot:item.public="{ item }">
        {{ item.public }}
      </template>
      <template v-slot:item.redeemLimit="{ item }">
        {{ item.redeemLimit }}
      </template>
      <template v-slot:item.expirationMonths="{ item }">
        {{ (item.expirationMonths) ? `${item.expirationMonths} months` : 'Never' }}
      </template>
      <template v-slot:item.id="{ item }">
        <v-btn v-if="item" x-small color="success" depressed @click="editConfig($event, item.id)">
          edit
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GiftCardConfigList',

  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Product',
          value: 'productName',
        },
        {
          text: 'Public',
          value: 'public'
        },
        {
          text: 'Redeem Limit',
          value: 'redeemLimit',
        },
        {
          text: 'Expiration',
          value: 'expirationMonths'
        },
        {
          text: '',
          value: 'id'
        }
      ],
    }
  },

  computed: {
    ...mapGetters({
      configs: 'gift-card-config/list'
    })
  },

  methods: {
    async getGiftCardConfigs() {
      await this.$store.dispatch('gift-card-config/find')
    },
    editConfig(event, id) {
      this.$router.push({ name: 'GiftCardEditConfigRoute', params: { configId: id } })
    },
    createConfig() {
      this.$router.push({ name: 'GiftCardCreateConfigRoute' } )
    }
  },

  async beforeMount() {
    await this.getGiftCardConfigs()
  }
}
</script>

<style lang="scss">

</style>