<template>
  <div class="gift-card-edit-config">
    <v-sheet class="pa-8 mb-10" v-if="editConfig">
      <v-form>
        <v-row>
          <v-col>
            <v-text-field outlined label="Name" v-model="editConfig.name" />
          </v-col>
          <v-col>
            <v-text-field
              outlined
              label="Gift Card Variant ID"
              hint="The ID of the MarianaTek product variant for this gift card. When the variant is purchased in MarianaTek this ECCO gift card will be supplied to the customer."
              v-model="editConfig.giftCardVariantId"
              />
          </v-col>
        </v-row>
        
        <v-row>
          <v-col>
            <v-select
              outlined
              @change="getProducts()"
              label="Product Type"
              v-model="selectedProductType"
              :items="productTypes"
              item-text="name"
              item-value="value"
              disabled
            />
          </v-col>
          <v-col v-if="selectedProductType !== 'accountbalance'">
            <v-text-field
              outlined
              v-model="editConfig.productName"
              label="Product Name"
              disabled
            />
          </v-col>

          <v-col v-if="selectedProductType === 'accountbalance'">
            <v-text-field
              label="Amount"
              outlined
              type="number"
              v-model="accountBalanceAmount"
              prefix="$"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              min="-1"
              outlined
              type="number"
              v-model="editConfig.redeemLimit"
              label="Redeem Limit"
              hint="For a gift card with no limit set this to -1"
            />
          </v-col>
          <v-col>
            <v-checkbox
              v-model="editConfig.canBeRedeemedByPurchaser"
              label="Allow purchaser to redeem gift card"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              type="number"
              min="1"
              outlined
              clearable
              v-model="editConfig.expirationMonths"
              label="Months before expiration"
              hint="Once a gift card is purchased it will be valid for this many months before expiring."
              suffix="months"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="editConfig.public"
              label="Public"
            />
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="editConfig.requireSavedCard"
              label="Require credit card on file"
            />
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="2">
            <v-btn :disabled="patchPending" :loading="patchPending" block color="primary" x-large depressed @click="saveConfig">Save</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>

    <v-sheet class="pa-8">
      <gift-card-instance-list :config-id="configId" />
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import GiftCardInstanceList from './GiftCardInstanceList'

export default {
  name: 'GiftCardEditConfig',

  components: {
    GiftCardInstanceList,
  },

  data() {
    return {
      editConfig: null,
      accountBalanceAmount: null,
      selectedProductType: null,
      selectedProduct: null,
      productTypes: [
        { name: 'Account Balance', value: 'accountbalance' },
        { name: 'Product', value: 'products' },
        { name: 'Contract', value: 'contracts' },
        { name: 'Credit', value: 'credits' },
        { name: 'Product Variant', value: 'variants' },
        { name: 'Package', value: 'packages' },
      ]
    }
  },

  computed: {
    ...mapGetters({
      configs: 'gift-card-config/list',
      contracts: 'contract/list',
      products: 'product/list',
      variants: 'product-variant/list',
      credits: 'credit/list',
      packages: 'package/list',
    }),
    configId() {
      return this.$route.params.configId
    },
    config() {
      return this.$store.state['gift-card-config'].keyedById[this.configId]
    },
    contractsLoading() {
      return this.$store.state['contract'].isFindPending
    },
    productsLoading() {
      return this.$store.state['product'].isFindPending
    },
    variantsLoading() {
      return this.$store.state['product-variant'].isFindPending
    },
    creditsLoading() {
      return this.$store.state['credit'].isFindPending
    },
    packagesLoading() {
      return this.$store.state['package'].isFindPending
    },
    patchPending() {
      return this.$store.state['gift-card-config'].isPatchPending
    }
  },

  watch: {
    selectedProductType(val) {
      if (val !== 'accountbalance') {
        this.accountBalanceAmount = null
        this.editConfig.isAccountBalance = false
      } else {
        this.editConfig.isAccountBalance = true
      }
    }
  },

  methods: {
    async getConfig(id) {
      await this.$store.dispatch('gift-card-config/get', id)
    },
    async saveConfig() {
      await this.$store.dispatch('gift-card-config/patch', [this.editConfig.id, {
        amount: (this.editConfig.isAccountBalance && this.accountBalanceAmount) ? (this.accountBalanceAmount * 100) : null,
        redeemLimit: this.editConfig.redeemLimit,
        expirationMonths: this.editConfig.expirationMonths,
        name: this.editConfig.name,
        canBeRedeemedByPurchaser: this.editConfig.canBeRedeemedByPurchaser,
        giftCardVariantId: this.editConfig.giftCardVariantId,
        public: this.editConfig.public,
        requireSavedCard: this.editConfig.requireSavedCard,
      }])
    },
    getProducts() {
      if (this.selectedProductType === 'contracts') {
        this.$store.dispatch('contract/find', {
          query: {
            limit: 200,
          }
        })
      } else if (this.selectedProductType === 'products') {
        this.$store.dispatch('product/find', {
          query: {
            limit: 200,
          }
        })
      } else if (this.selectedProductType === 'variants') {
        this.$store.dispatch('product-variant/find', {
          query: {
            limit: 200,
            is_public: true,
            is_inventoriable: false,
          }
        })
      } else if (this.selectedProductType === 'credits') {
        this.$store.dispatch('credit/find', {
          query: {
            limit: 200,
          }
        })
      } else if (this.selectedProductType === 'packages') {
        this.$store.dispatch('package/find', {
          query: {
            limit: 200,
          }
        })
      }
    },
  },

  async beforeMount() {
    await this.getConfig(this.$route.params.configId)

    this.selectedProductType = this.config.productType
    
    this.accountBalanceAmount = this.config.amount / 100
    this.editConfig = JSON.parse(JSON.stringify(this.config))
    await this.getProducts()
      let product = null
      switch(this.selectedProductType) {
        case 'contracts':
          product = this.contracts.find((o) => o.id === this.config.productId)
          break
        case 'products':
          product = this.products.find((o) => o.id === this.config.productId)
          break
        case 'variants':
          product = this.variants.find((o) => o.id === this.config.productId)
          break
        case 'credits':
          product = this.credits.find((o) => o.id === this.config.productId)
          break
        case 'packages':
          product = this.packages.find((o) => o.id === this.config.productId)
          break
      }

      console.log(product)

      this.selectedProduct = product
    
  }
}
</script>

<style lang="scss">

</style>