var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gift-card-config-list"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"mb-4",attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"block":"","depressed":"","color":"primary"},on:{"click":_vm.createConfig}},[_vm._v("Add Config")])],1)],1),(_vm.configs)?_c('v-data-table',{attrs:{"options":{ itemsPerPage: -1 },"sort-by":"purchaseDate","headers":_vm.headers,"items":_vm.configs},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.productName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.productName)+" ")]}},{key:"item.public",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.public)+" ")]}},{key:"item.redeemLimit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.redeemLimit)+" ")]}},{key:"item.expirationMonths",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((item.expirationMonths) ? ((item.expirationMonths) + " months") : 'Never')+" ")]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [(item)?_c('v-btn',{attrs:{"x-small":"","color":"success","depressed":""},on:{"click":function($event){return _vm.editConfig($event, item.id)}}},[_vm._v(" edit ")]):_vm._e()]}}],null,false,2781674676)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }